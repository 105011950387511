import { ReportError } from '@/error_handling.js';

const REGEXP_EXTENSION = /\.\w+$/;

export class ConvertPDF {
  async process(file) {
    if (file.type !== 'application/pdf') {
      return file;
    }

    const data = await this.readFile(file);

    const pdfjsLib = await this.loadPdfjsLib();

    const pdf = await pdfjsLib.getDocument(data).promise;
    const firstPage = await pdf.getPage(1);

    const image = await this.getPageImage(pdfjsLib, firstPage);
    const newFile = {
      name: 'upload.jpg',
      data: image,
      size: image.size,
      type: 'image/jpeg',
    };
    if (file.name) {
      newFile.name = file.name.replace(REGEXP_EXTENSION, '.jpg');
    }
    if (!image.name) {
      image.name = newFile.name;
    }

    return newFile;
  }

  async getPageImage(pdfjsLib, pdfPage) {
    const operatorList = await pdfPage.getOperatorList();
    return new Promise((resolve, reject) => {
      const idx = operatorList.fnArray.findIndex(element => {
        return element === pdfjsLib.OPS.paintImageXObject;
      });

      if (idx >= 0) {
        const imageName = operatorList.argsArray[idx][0];

        pdfPage.objs.get(imageName, (image) => {
          try {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image.bitmap, 0, 0);
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Failed to process PDF'));
              }
            }, 'image/jpeg', 0.8);
          } catch (ex) {
            ReportError(ex);
            reject(new Error('Failed to process PDF'));
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (_ev) => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (_ev) => {
        reject(new Error('Failed to load PDF'));
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  async loadPdfjsLib() {
    const module = await import('@/imports/pdflib.js');
    return module.pdfjsLib;
  }
}
